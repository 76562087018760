import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierDrive = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierDrive {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Drive Watches for Sale'}
      canonical={'/fine-watches/cartier/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Drive watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/model/drive,drive-moonphase/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-drive-header.png"
                alt="Pre-Owned Certified Used Cartier Drive Header"
                aria-label="Used Cartier Drive Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTER DRIVE</h1>
            <h2>
              {' '}
              CARTIER DRIVE: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED CARTIER WATCHES
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE OWNED CARTIER DRIVE WATCHES WITH
              GRAY AND SONS JEWELERS
            </h3>
            <p>
              What are the main features of the Drive de Cartier watch? It’s the unique curved case,
              faceted sapphire dial, and domed crystal. Created in 2016, Drive de Cartier is one of
              the youngest Cartier watch collections. The unique case of Drive de Cartier watches
              looks fantastic in combination with alligator-skin straps in a variety of colors to
              choose from. This model represents the highest level of elegance, timelessness, and
              sophistication, which is exactly what we expect from such a luxury brand as Cartier.
              If you want to buy pre-owned Cartier Drive, sell pre-owned Cartier Drive, trade
              pre-owned Cartier Drive, or repair pre-owned Cartier Drive watch, check our vast
              inventory of Cartier Drive pre-owned, estate, vintage, antique, heirloom and used
              Cartier Watches.
              <br />
              <br />
              Want to buy, sell, trade, consign and repair used and pre owned Cartier Drive watches?
              Do it at Gray &amp; Sons. At Gray &amp; Sons, you can buy Preowned Cartier Drive Watch
              for the best price. Known as the top luxury watch seller in the U.S, Gray &amp; Sons
              offers a huge selection of fine Swiss watches, pre-owned, and estate Cartier watches.
              Exceptional prices and a large, in-stock inventory of Rolex watches, only at Gray
              &amp; Sons. We offer certified pre-owned watches.
              <br />
              <br />
              Gray &amp; Sons is a top place to buy pre-owned Cartier Drive, sell pre-owned Cartier
              Drive, trade pre-owned Cartier Drive, or repair pre-owned Cartier Drive. Are you
              interested in buying? We provide an exclusive in-house warranty for all certified
              pre-owned Cartier watches, including Cartier Drive pre-owned watch, Cartier
              Drive estate watch, Cartier Drive vintage watch, Cartier Drive antique watch, Cartier
              Drive heirloom watch, and Cartier Drive used watch, and a two-year warranty for modern
              Rolex watches. Gray &amp; Sons only offers watches that have been recently serviced,
              detailed, freshly lubricated, and timing adjusted. Among many iconic Cartier
              wristwatches available at Gray &amp; Sons, you will find Cartier Drive pre-owned
              watch, Cartier Drive estate watch, Cartier Drive vintage watch, Cartier Drive antique
              watch, Cartier Drive heirloom watch, Cartier Drive used watch, Cartier Tank, Cartier
              Pasha, Cartier Santos, Cartier Ballon Bleu. Gray &amp; Sons also offers pre-owned
              Patek Philippe, Rolex, and Audemars Piguet watches.
              <br />
              <br />
              Are you interested? Check out our Cartier watch inventory here, and contact our
              representative for further assistance on{' '}
              <a href="/fine-watches/cartier/">www.grayandsons.com</a> or chat now with our
              specialists Rich and Vika via this link, <a href="/chat-with-rich">CHAT HERE</a> to
              make the best deal with our decision-makers. You can meet in person with our highly
              qualified in-house watchmakers by visiting Gray &amp; Sons’ Showroom in Surfside,
              Miami and ask any question about the buying process, prices or details on our
              pre-owned certified luxury watches. Gray &amp; Sons is the leading
              independent, unauthorized Cartier watch dealer with inventory control and price
              flexibility, and the #1 place recommended by our clients to buy pre-owned Cartier
              Drive, sell pre-owned Cartier Drive, trade pre-owned Cartier Drive, or repair
              pre-owned Cartier Drive watch.
              <br />
              <br />
              Interested in selling? Wondering “What’s my watch worth?” Evaluate your watch at Sell
              Us Your Jewelry and sell your lux watch today. If you don’t know what Sell Us Your
              Jewelry is - it’s Gray &amp; Sons buying division that has become the leading watch
              buyer and the top-rated place to sell your watch Miami and sell your jewelry Miami,
              Florida. Rated by the clients as the #1 watch buyer, Sell Us Your Jewelry is offering
              the highest prices in the shortest time for luxury jewelry watches. The #1 best choice
              when you want to buy pre-owned Cartier Drive, sell pre-owned Cartier Drive, trade
              pre-owned Cartier Drive, or repair pre-owned Cartier Drive watch. Visit{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com</a>
              , the online jewelry buyer and online watch buyer where you can sell used Cartier
              Drive, trade used Cartier Drive, buy used Cartier Drive, or repair used Cartier Drive
              watch. If you’re asking where can I sell my used watch or where can I sell my jewelry,
              Gray &amp; Sons is the place where you can buy used Cartier Drive, sell used Cartier
              Drive, trade used Cartier Drive, or repair used Cartier Drive, and sell your watch or
              sell your jewelry for cash today.
              <br />
              <br />
              Looking for a repair shop in Miami? Gray &amp; Sons is a professional watch repair
              shop offering Cartier watch battery replacement for certain models along with many
              other services. Our customers recommend Gray &amp; Sons as the #1 Miami used watch
              buyer and seller in Miami, Miami Beach, Coconut Grove, South Beach, Bal Harbour,
              Surfside, Sunny Isles, Coral Gables, Aventura, Fort Lauderdale, Brickle, Pinecrest,
              and many more areas in Florida. Gray &amp; Sons has been serving thousands of buyers
              and sellers of pre-owned Cartier Drive watches for over 42 years as a professional
              luxury watch and jewelry store located in Surfside, Miami. Come to Gray &amp; Sons,
              open six days a week, and talk to our highly qualified watch and jewelry experts
              on-site.
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/filter/model/drive,drive-moonphase/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierDrive
